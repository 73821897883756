import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Icon from '@mui/material/Icon';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export interface NumberPadProps{
    onKeyPressed:(n:string)=>void;
    buttons?:ButtonDefinition[]|undefined;
    buttonsInRow?:number|undefined;
}

export interface ButtonDefinition{
    text?:string;
    value:string;
    specialUseCase?:ButtonUseCase|undefined;
}

export enum ButtonUseCase{
    None = 'None',
    Backspace = 'Backspace'
}

export const NumberPad = (props:NumberPadProps)=>{
    
    const buttons:ButtonDefinition[] = props.buttons || [{value:'1', text:'1'},{value:'2', text:'2'},{value:'3', text:'3'},{value:'4', text:'4'},{value:'5', text:'5'},{value:'6', text:'6'},{value:'7', text:'7'},{value:'8', text:'8'},{value:'9', text:'9'},{value:',', text:','},{value:'0', text:'0'}, {value:ButtonUseCase.Backspace.toString(), specialUseCase:ButtonUseCase.Backspace}];
    const buttonsInRow = props.buttonsInRow || 3;

    const renderButtonsRow = (rowKey:string, buttons:ButtonDefinition[])=>{
        return (
            <div>
                <ButtonGroup size="large" fullWidth={true} variant="contained" aria-label="outlined primary button group">
                    {buttons.map((b)=>{
                        if(b.text!=='' && !b.specialUseCase){
                            return(
                                <Button key={`key-${b.value}`} onClick={()=>{props.onKeyPressed(b.value);}}>{b.text}</Button>
                            );
                        }
                        else if(b.specialUseCase){
                            switch(b.specialUseCase){
                                case ButtonUseCase.Backspace:
                                    return(
                                        <Button key={`key-${b.value}`} onClick={()=>{props.onKeyPressed(b.value);}}><KeyboardBackspaceIcon/></Button>
                                    );
                            }
                           
                        }
                        throw 'Button need to have text or specialUseCase.';
                    })}
                </ButtonGroup>
            </div>
        );
    }

    const renderButtons = ()=>{
        let rows = [];
        let toBeAdded:ButtonDefinition[] = [];
        for(let i=0;i<buttons.length;i++){
            if(i>0 && i%buttonsInRow==0){
                rows.push(
                    renderButtonsRow(`row-${rows.length}`, toBeAdded)
                );
                toBeAdded = [];
            }  
            toBeAdded.push(buttons[i]);
        }
        if(toBeAdded.length>0){
            rows.push(
                renderButtonsRow(`row-last`, toBeAdded)
            );
        }
        return rows;
    }
    return (
        <div>
            {renderButtons()}
        </div>
    );
}