import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

type DisplayProps = {
    value:string
}

export const Display = ({value}:DisplayProps)=>{
    return (
        <Typography variant="h5" align='center'>
            {value}
        </Typography>
    );
}