import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";

export interface ConfirmationStepProps{
    info:string;
}

export const ConfirmationStep = (props:ConfirmationStepProps)=>{
    return (
        <React.Fragment>
            <div>Transaction completed!</div>
            <CheckIcon style={{fontSize:'4em',color:'green'}}/>
            <div>{props.info}</div>
            <Button onClick={()=>{window.location.reload();}}>New transaction</Button>
        </React.Fragment>
    );
}