import { useSearchParams } from "react-router-dom";
import { WalletConnectorControl } from "../Algo/WalletConnectorControl";
import { UrlUtils } from "../Common/UrlUtils";

export const SenderMain = ()=>{
    let [searchParams, setSearchParams] = useSearchParams();
    const ammount = searchParams.get(UrlUtils.PaymentValueQueryParameter) || '0';
    const target =  searchParams.get(UrlUtils.PaymentTargetQueryParameter) || '';
    const memo = searchParams.get(UrlUtils.PaymentMemoQueryParameter) || '';
    const txnId = searchParams.get(UrlUtils.PaymentTransactionTimestamp) || '';
    const terminalId =  searchParams.get(UrlUtils.PaymentTransactionTerminalId) || '';
    const asaId = searchParams.get(UrlUtils.PaymentAsaId) || '0';
    const isTestnet =  searchParams.get(UrlUtils.IsTestnet)?.toLowerCase() == 'true' || true;
    return (
    <div>
        <WalletConnectorControl ammount={ammount} receiver={target} memo={memo} sessionId={`${txnId}-${terminalId}`} asaID={Number.parseInt(asaId)} isTestnet={isTestnet}/>
    </div>);
}