import React from "react";
import { useState } from "react";
import { Display } from "../../Components/Display";
import { ButtonUseCase, NumberPad } from "../../Components/NumberPad";

export interface InputStepProps{
    onValueChanged:(val:string)=>void
}

export const InputStep = (props:InputStepProps)=>{
    const [value, setValue] = useState<string>('');
    
    const onKeyPressed = (val:string)=>{
        if(val!==ButtonUseCase.Backspace){
            let newVal = value+val;
            setValue(newVal);
            props.onValueChanged(newVal);
        }
        else{
            let newVal = value.slice(0, -1);
            setValue(newVal)
            props.onValueChanged(newVal);
        }
    }
    return (
        <React.Fragment>
            <Display value={value !== '' ? value : '0'}/>
            <NumberPad onKeyPressed={onKeyPressed}/>
        </React.Fragment>
    );
}