


export class AsaBase{
    //In case of Algo => undefined
    public id:number|undefined;
    public decimals:number;
    public name:string;

    constructor(id:number|undefined, decimals:number, name:string){
        this.id = id;
        this.name = name;
        if(decimals<0){
            throw 'Decimals must be equal or greater than 0';
        }
        this.decimals = decimals;
    }

    public fromHumanReadableToTransactionAmmount(ammount:number){
        debugger;
        if(this.decimals===0){
            return ammount;
        }
        return ammount * Math.pow(10,this.decimals);
    }
    
    public toHumanReadableAmmount(ammount:number){
        if(this.decimals===0){
            return ammount;
        }
        return ammount * Math.pow(10,this.decimals * (-1));
    }

    public static AsAlgo():AsaBase{
        return new AsaBase(undefined, 6, "Algo");
    }
}
