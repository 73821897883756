import * as QRCode from 'qrcode';
import React from 'react';
import { useEffect, useRef } from 'react';
import { AlgoSDKHelper } from '../../Algo/AlgoSDKHelper';
import { AppSettings } from '../../Common/AppSettings';
import { UrlUtils } from '../../Common/UrlUtils';

export type QRStepProps = {
    transactionValue:number;
    visible:boolean;
    onTransactionConfirmed:(transaction:any)=>void;
}


export const QRStep = (props:QRStepProps)=>{

    const canvasContainerRef = useRef<HTMLDivElement>(null);
    const hrefRef = useRef<HTMLAnchorElement>(null);
    const buildTransactionUrl = (ammount:number, memo:string)=>{
        return UrlUtils.BuildPaymentUrl(AppSettings.BaseUrl, AppSettings.SelectedAsa.id || 0, ammount, AppSettings.TargetWallet, memo)
    }
    useEffect(()=>{
        if(props.visible){
            let sdkHelper = new AlgoSDKHelper(AppSettings.IsTestnet);
            const memo = 'algoterminal_' + AppSettings.TerminalId + Date.now();
            const url = buildTransactionUrl(props.transactionValue, memo);
            QRCode.toCanvas(url, { errorCorrectionLevel: 'H' }, function (err, canvas) {
                if (err) throw err;
                canvasContainerRef.current?.appendChild(canvas)
                if(hrefRef.current){
                    hrefRef.current.href = hrefRef.current.text= url
                }
              });
            sdkHelper.transactionWithMemoConfirmed(AppSettings.TargetWallet, memo).then((data)=>{
                props.onTransactionConfirmed(data);
            });
        }
        else{
            
        }
    }, [props.visible]);

  
    
    return (
        <React.Fragment>
            <div ref={canvasContainerRef}></div>
            <a target='_blank' ref={hrefRef}></a>
        </React.Fragment>
    )

}