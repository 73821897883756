

import React, { useState } from 'react';
import { Avatar, Box, Button, Container, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Modal, Typography } from '@mui/material';
import { style } from '@mui/system';
import { IWalletConnector, WalletConnector, WalletType } from './WalletConnector';
import CircularProgress from '@mui/material/CircularProgress';
import buffer from 'buffer';
import { useEffect } from 'react';
import { SignTransactionButton } from './SingTransactionButton';
import { AppSettings } from '../Common/AppSettings';
import { Transaction } from 'algosdk';
import myAlgoWalletLogo from  '../Assets/MyAlgoLogo.svg';
import peraWalletLogo from  '../Assets/PeraWalletLogo.svg';
import { AlgoSDKHelper } from './AlgoSDKHelper';
import { AsaBase } from './Asa';

export interface WalletConnectorControlProps{
  ammount:string;
  receiver:string;
  memo:string;
  sessionId:string;
  asaID:number;
  isTestnet:boolean;
}

export const WalletConnectorControl = (props:WalletConnectorControlProps)=>{
  const sessionIdKey = "sessionId";
  const {Buffer} = buffer;
        if (!((window as any).Buffer)) (window as any).Buffer = Buffer;
        

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState<WalletConnector|undefined>(undefined);
  const [transaction, setTransactions] = useState<Transaction>();
  const [isConnected, setIsConnected] = useState(false);
  
  

  useEffect(()=>{
    debugger;
    if(selectedConnector!==undefined && props.asaID !== undefined){
      let sdkHelper = new AlgoSDKHelper(props.isTestnet);
      if(props.asaID===0){
        const asa = AsaBase.AsAlgo();
        selectedConnector.Connect().then((r)=>{
          if(r){
            window.localStorage.setItem(sessionIdKey, props.sessionId);
            let number = Number.parseFloat(props.ammount);
            selectedConnector.createTransaction(r[0].address, props.receiver, asa?.fromHumanReadableToTransactionAmmount(number), props.memo, asa.id).then((t:Transaction)=>{
              setTransactions(t);
            }, (transactionCreationError)=>{
              alert(transactionCreationError);
            });
            setIsConnected(true);
          }
          else{
            alert("Error connecting");
          }
        });
      }
      else{
        sdkHelper.getAsaById(props.asaID).then((asa)=>{
          if(asa){
            selectedConnector.Connect().then((r)=>{
              if(r){
                window.localStorage.setItem(sessionIdKey, props.sessionId);
                let number = Number.parseFloat(props.ammount);
                selectedConnector.createTransaction(r[0].address, props.receiver, asa?.fromHumanReadableToTransactionAmmount(number), props.memo, asa.id).then((t:Transaction)=>{
                  setTransactions(t);
                }, (transactionCreationError)=>{
                  alert(transactionCreationError);
                });
                setIsConnected(true);
              }
              else{
                alert("Error connecting");
              }
              
            });
          }
          else{
            alert("Error getting ASA");
          }
        }, (getASAError)=>{
          alert(getASAError);
        });
      }
    }
  }, [selectedConnector])


  const onListItemClick = (walletType:WalletType)=>{
      let connector = new WalletConnector(walletType, AppSettings.IsTestnet);
      setSelectedConnector(connector);
  };
 
  //Ensure no old sessions
  let sessionId = window.localStorage.getItem(sessionIdKey);
  if(selectedConnector === undefined && sessionId && sessionId !== props.sessionId){
    window.localStorage.clear();
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const getConnectedScreen = ():JSX.Element=>{
    if(isConnected && selectedConnector && transaction){
      return (
        <Box sx={{height: '100vh', verticalAlign:'center' }}>
          <div>FROM: {selectedConnector?.Wallets[0].address}</div>
          <div>TO: {props.receiver}</div>
          <div>AMOUNT: {props.ammount} {AppSettings.SelectedAsa.name}</div>
          <SignTransactionButton connector={selectedConnector} transaction={transaction} message="Please sign"/>
        </Box>
      );
    }
    return (
      <React.Fragment>
        <CircularProgress />
      </React.Fragment>
    );
  }


  const getConnectionScreen = ():JSX.Element=>{
    return (
      <React.Fragment>
        <Container maxWidth="sm">
          <Box sx={{height: '100vh', verticalAlign:'center', paddingTop:'25%'}}>
            <Button variant="contained" onClick={()=>{setModalOpened(true)}}>Connect</Button>
          </Box>
          <Modal
            open={modalOpened}
            onClose={()=>{setModalOpened(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItemButton component="div" onClick={onListItemClick.bind(this, WalletType.AlgorandMobileWallet)}>
                <ListItemAvatar>
                    <Avatar alt="Pera Wallet" src={peraWalletLogo} />
                  </ListItemAvatar>
                <ListItemText primary="Pera Wallet" />
              </ListItemButton>
               
                <Divider variant="inset" component="li" />
                <ListItemButton component="div" onClick={onListItemClick.bind(this, WalletType.MyAlgoWallet)}>
                  <ListItemAvatar>
                      <Avatar alt="My Algo Wallet" src={myAlgoWalletLogo} />
                    </ListItemAvatar>
                  <ListItemText primary="My Algo Wallet" />
              </ListItemButton>
              </List>
            </Box>
          </Modal>
        </Container>
      </React.Fragment>
    );
  }


  if(selectedConnector===undefined){
    return getConnectionScreen();
  }

  return getConnectedScreen();
}