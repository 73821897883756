import { Button, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AsaBase } from '../Algo/Asa';
import { AppSettings, SettingsObject } from '../Common/AppSettings';
import { AsaSelector } from '../Components/AsaSelector';

export interface SettingsDrawerProps{
    isOpened:boolean;
    onClose?:()=>void;
    allowClose?:boolean;
}

export const SettingsDrawer = (props:SettingsDrawerProps)=>{

    const [settings, setSettings] = useState<SettingsObject>(AppSettings.SettingFromStorageOrDefault);

    const inputChanged = (ev:any)=>{
        let key = ev.currentTarget.id;
        let updateObj = {...settings} as any;
        updateObj[key] = ev.currentTarget.value;
        setSettings(updateObj);
    }

    const checkBoxChanged = (ev:any, checked:boolean)=>{
        let key = ev.currentTarget.id;
        let updateObj = {...settings} as any;
        updateObj[key] = checked;
        setSettings(updateObj);
    }

    const onAsaSelectionChanged = (asa:AsaBase|null)=>{
        let updateObj = {...settings};
        updateObj.SelectedAsa = asa || AsaBase.AsAlgo();
        setSettings(updateObj);
    }

    const saveClicked = ()=>{
        AppSettings.SetAppSettings(settings);
        props.onClose && props.onClose();
    }

    return (
        <React.Fragment>
            <Typography variant="h5" align='center'>
                Settings
            </Typography>
            <Divider />
            <Stack spacing={2}>
                <TextField id='TargetWallet' label="Target wallet" value={settings.TargetWallet} onChange={inputChanged}/>
                <TextField id='TerminalDisplayName' label="TerminalDisplayName" value={settings.TerminalDisplayName} onChange={inputChanged}/>
                <AsaSelector onAsaSelectionChanged={onAsaSelectionChanged} currentAsa={settings.SelectedAsa}/>
                <FormControlLabel control={<Checkbox id='IsTestnet' onChange={checkBoxChanged} checked={settings.IsTestnet} />} label="Use Testnet" />
                <Button variant="contained" onClick={saveClicked}>Save</Button>
            </Stack>
        </React.Fragment>
    )
}