import { AsaBase } from "../Algo/Asa";

export class AppSettings{

    private static _settingsObject:SettingsObject;

    public static get RudimentarySettingsProvided(){
        const settings = AppSettings.SettingFromStorageOrDefault();
        if(!settings){
            return false;
        }
        if(!settings.TargetWallet || settings.TargetWallet == ''){
            return false;
        }
        return true;
    }

    public static get BaseUrl(){
        return window.location.href;
    }

    public static get TargetWallet(){
        return AppSettings.SettingFromStorageOrDefault().TargetWallet;
    }

    public static set TargetWallet(val:string){
        AppSettings.SettingFromStorageOrDefault().TargetWallet = val;
        AppSettings.UpdateStorage();
    }

    public static get TerminalIdentity(){
        return AppSettings.SettingFromStorageOrDefault().TerminalDisplayName;
    }

    public static set TerminalIdentity(val:string){
        AppSettings.SettingFromStorageOrDefault().TerminalDisplayName = val;
        AppSettings.UpdateStorage();
    }

    public static get TerminalId(){
        return 'algo-terminalwkpl';
    }

    public static get IsTestnet(){
        return AppSettings.SettingFromStorageOrDefault().IsTestnet;
    }

    public static set IsTestnet(val:boolean){
        AppSettings.SettingFromStorageOrDefault().IsTestnet = val;
        AppSettings.UpdateStorage();
    }

    public static get SelectedAsa(){
        return AppSettings.SettingFromStorageOrDefault().SelectedAsa;
    }

    public static set SelectedAsa(val:AsaBase){
        AppSettings.SettingFromStorageOrDefault().SelectedAsa = val;
        AppSettings.UpdateStorage();
    }
    

    private static readonly _settingsKey = 'algoterminal_settings';
    public static SettingFromStorageOrDefault(){
        if(!AppSettings._settingsObject){
            let storageValue = localStorage.getItem(AppSettings._settingsKey);
            if(storageValue){
                try{
                    let obj = JSON.parse(storageValue);
                    AppSettings._settingsObject = obj;
                }
                catch{}
            }
            if(!AppSettings._settingsObject){
                AppSettings._settingsObject = new SettingsObject();
                localStorage.setItem(AppSettings._settingsKey, JSON.stringify(AppSettings._settingsObject));
            }
        }
        return AppSettings._settingsObject;
    }

    public static SetAppSettings(obj:SettingsObject){
        AppSettings._settingsObject = obj;
        AppSettings.UpdateStorage();
    }

    private static UpdateStorage(){
        localStorage.setItem(AppSettings._settingsKey, JSON.stringify(AppSettings._settingsObject));
    }
}

export class SettingsObject{
    public BaseUrl:string = '';
    public TargetWallet:string = '';
    public TerminalDisplayName:string = 'Algo Terminal';
    public TerminalId:string = '';
    public IsTestnet:boolean = true;
    public SelectedAsa:AsaBase = AsaBase.AsAlgo();
}