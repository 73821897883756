import { AppSettings } from "./AppSettings";

export class UrlUtils{

    public static PaymentValueQueryParameter = 'value';
    public static PaymentTargetQueryParameter = 'target';
    public static PaymentMemoQueryParameter = 'memo';
    public static PaymentTransactionTimestamp = 't';
    public static PaymentTransactionTerminalId = 'tid';
    public static ModeQueryParameter = 'm';
    public static PaymentPagePath = 'payment';
    public static ReceiverPagePath = 'receiver';
    public static PaymentAsaId = 'aid';
    public static IsTestnet = "t";

    public static BuildPaymentUrl(baseUrl:string, asaId:number, ammount:number, target:string, memo:string):string{
        return `${UrlUtils.EnsureTrailingSlash(baseUrl)}?${UrlUtils.ModeQueryParameter}=1&${UrlUtils.PaymentValueQueryParameter}=${ammount}&${UrlUtils.PaymentTargetQueryParameter}=${target}&${UrlUtils.PaymentMemoQueryParameter}=${memo}&${UrlUtils.PaymentTransactionTerminalId}=${AppSettings.TerminalId}&${UrlUtils.PaymentTransactionTimestamp}=${Date.now()}&${UrlUtils.PaymentAsaId}=${asaId}&${UrlUtils.IsTestnet}=${AppSettings.IsTestnet}`;
    }

    private static EnsureTrailingSlash(val:string):string{
        val += val.endsWith("/") ? "" : "/";
        return val;
    }
}