import { Button, CircularProgress } from "@mui/material";
import { Transaction } from "algosdk";
import React from "react";
import { useState } from "react";
import { WalletConnector } from "./WalletConnector";
import { isAndroid, isIOS } from "react-device-detect";
import CheckIcon from '@mui/icons-material/Check';

export interface SignTransactionButtonProps{
    connector:WalletConnector;
    transaction:Transaction;
    message:string;
}

export const SignTransactionButton = (props:SignTransactionButtonProps)=>{

    const [isSigning, setIsSigning] = useState(false);
    const [afterSendMessage, setAfterSendMessage] = useState<string|undefined>(undefined);

    const buttonClicked = ()=>{
        setIsSigning(true);
        props.connector.SingAndSendTransaction(props.transaction, props.message).then((r:any)=>{
            setAfterSendMessage(JSON.stringify(r));
        }, (err)=>{
            setAfterSendMessage(err.message);
        });
    }

    const urlForButton = ()=>{
        if(isAndroid){
            return props.connector.MobileIntentAndroid();
        }
        if(isIOS){
            return props.connector.MobileIntentIphone();
        }
        return '';
    }
    if(afterSendMessage){
        return (
            <React.Fragment>
                <div>Transaction completed!</div>
                <CheckIcon style={{fontSize:'4em',color:'green'}}/>
                <div>{afterSendMessage}</div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {isSigning?
            <CircularProgress />
            :
            <Button href={urlForButton()} variant="contained" onClick={buttonClicked.bind(this)}>Sing transaction</Button>
            }
            
        </React.Fragment>
    );
}