import MyAlgoConnect, { SignedTx } from '@randlabs/myalgo-connect';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";

import { formatJsonRpcRequest } from "@json-rpc-tools/utils";

import { AppSettings } from '../Common/AppSettings';
import { AlgoSDKHelper } from './AlgoSDKHelper';
import algosdk, { Transaction } from 'algosdk';

export enum WalletType{
    Unknown,
    AlgorandMobileWallet,
    MyAlgoWallet
}

export class WalletConnector{
    
    private _connectorInstance!: IWalletConnector;
    private _algoSdkHelper!:AlgoSDKHelper;

    constructor(private _type:WalletType, testnet:boolean = false){
        this.InitializeConnector();
        this.InitializeSDKClient(testnet);
    }

    private InitializeSDKClient(testnet:boolean ){
        this._algoSdkHelper = new AlgoSDKHelper(testnet);
    }

    private InitializeConnector(){
        switch(this._type){
            case WalletType.AlgorandMobileWallet: this._connectorInstance = new AlgorandMobileWalletConnector();break;
            case WalletType.MyAlgoWallet: this._connectorInstance = new MyAlgoWalletConnector();break;
        }
    }

    public async Connect():Promise<WalletAccount[] | undefined>{
        let accounts = await this._connectorInstance.connect();
        return accounts;
    }

    public async SingAndSendTransaction(txn:Transaction, message:string):Promise<any>{
        const signed = await this._connectorInstance.singTransaction(txn, message);
        await this._algoSdkHelper.sendRawTransaction(signed);
        return await this._algoSdkHelper.waitForConfirmation(txn.txID());
    }

    
    public createTransaction(from:string, to:string, amount:number, note:string, asaId:number | undefined):Promise<Transaction>{
        //In case of ADA => asaId === undefined
        return this._algoSdkHelper.createTransaction(
            from, 
            to, 
            amount,
            note,
            asaId
        );
    }

    public transactionWithMemoConfirmed(targetWallet:string, memo:string, cancellation?:Promise<void>):Promise<boolean>{
        return this._algoSdkHelper.transactionWithMemoConfirmed(targetWallet, memo, cancellation);
    }

    public get Wallets():WalletAccount[]{
        return this._connectorInstance.wallets();
    }

    public MobileIntentAndroid():string{
        return this._connectorInstance.mobileIntentAndroid();
    }

    public MobileIntentIphone():string{
        return this._connectorInstance.mobileIntentIPhone();
    }
   
}

class AlgorandMobileWalletConnector implements IWalletConnector{
    
    private _connector:WalletConnect;
    private _wallets:WalletAccount[] = [];

    constructor(){
        this._connector = new WalletConnect({
            bridge: "https://bridge.walletconnect.org", // Required
            clientMeta:{
                description: AppSettings.TerminalIdentity,
                url: AppSettings.BaseUrl,
                icons: ["https://nodejs.org/static/images/logo.svg"],
                name: AppSettings.TerminalIdentity
            },
            qrcodeModal: QRCodeModal,
          });
          
    }

    public async connect():Promise<WalletAccount[] | undefined>{
        if (!this._connector.connected) {
            try{
                await this._connector.connect();
            }
            catch(e){
                return undefined;
            }
        }
        this._wallets = this._connector.accounts.map((a)=>{return {address:a} as WalletAccount});
        return this._wallets;
    }

    public wallets():WalletAccount[]{
        if (!this._connector.connected) {
            throw 'not connected';
        }
        return this._wallets;
    }

    public disconnect():Promise<void>{
        return this._connector.killSession();
    }
    
    public async singTransaction(txn: Transaction, message:string = ''):Promise<any>{
        const encodedTxn = {txn:Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64")};
        const requestParams = [[encodedTxn]];
        const request = formatJsonRpcRequest("algo_signTxn", requestParams);
        const result: Array<string | null> = await this._connector.sendCustomRequest(request);
        const decodedResult = result.map(element => {
            return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
          });
        return decodedResult;
    }

    public mobileIntentAndroid(){
        return 'algorand://open.app';
    }

    public mobileIntentIPhone(){
        return 'algorand://';
    }
}

class MyAlgoWalletConnector implements IWalletConnector{
    
    private _connector:MyAlgoConnect;
    private _wallets:WalletAccount[] = [];

    constructor(){
        this._connector = new MyAlgoConnect();
    }

    public async connect():Promise<WalletAccount[] | undefined>{
        const accounts = await this._connector.connect();
        this._wallets = accounts.map((a)=>{return {address:a.address} as WalletAccount});
        return this._wallets;
    }

    public wallets():WalletAccount[]{
        return this._wallets;
    }

    public disconnect():Promise<void>{
        throw 'Not implemented';
    }
    
    public async singTransaction(txn: Transaction, message:string = ''):Promise<Uint8Array>{
        const result: SignedTx = await this._connector.signTransaction(txn.toByte());
        return result.blob;
    }
    public mobileIntentAndroid(){
        return '';
    }

    public mobileIntentIPhone(){
        return '';
    }

}



export interface IWalletConnector{
    connect:()=>Promise<WalletAccount[] | undefined>;
    wallets:()=>WalletAccount[];
    disconnect:()=>Promise<void>;
    singTransaction:(txn:Transaction, message:string)=>Promise<Uint8Array>;
    mobileIntentAndroid:()=>string;
    mobileIntentIPhone:()=>string;
}

type WalletAccount = {
    address:string
}

