import './App.css';
import { ReceiverMain } from './Receiver/Main';
import { useSearchParams } from "react-router-dom";
import { SenderMain } from './Sender/Main';
import { UrlUtils } from './Common/UrlUtils';

function App() {
  let [searchParams, setSearchParams] = useSearchParams();
  const isPayment = (searchParams.get(UrlUtils.ModeQueryParameter) || '0') === '1'; 
  
  return (
    <div className="App" style={{padding:'5%'}}>
      {isPayment ?
      <SenderMain />
      :
      <ReceiverMain />
      }
    </div>
  );
}

export default App;
