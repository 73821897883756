import * as React from 'react';
import { useState } from 'react';
import { Display } from '../Components/Display';
import { NumberPad } from '../Components/NumberPad';
import Box from '@mui/material/Box';
import { ProcessStepProps } from '../Components/ProcessStep';
import { InputStep } from './StepsContent/InputStep';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { QRStep } from './StepsContent/QRStep';
import { Button, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AppSettings } from '../Common/AppSettings';
import { SettingsDrawer } from './SettingsDrawer';
import { ConfirmationStep } from './StepsContent/ConfirmationStep';

interface AppState{
    transactionValue:number;
    activeIndex:number;
    openSettings:boolean;
    transactionData:any;
}

export const ReceiverMain = ()=>{

    const [state, setState] = React.useState<AppState>({
        transactionValue:0,
        activeIndex:0,
        openSettings:!AppSettings.RudimentarySettingsProvided,
        transactionData:undefined
    });

    const inputValueChanged = (val:string)=>{
        setState({
            ...state,
            transactionValue:Number.parseFloat(val.replace(',','.'))
        });
    }

    const onTransactionConfirmed = (transactionData:any)=>{
        setState({
            ...state,
            transactionData:transactionData,
            activeIndex:++state.activeIndex
        });
    }

    const steps:ProcessStepProps[] = [
        {
            name:'Type amount',
            component:InputStep({onValueChanged:inputValueChanged})
        },
        {
            name:'Customer scans code',
            component:QRStep({transactionValue:state.transactionValue, visible:state.activeIndex===1, onTransactionConfirmed:onTransactionConfirmed})
        },
        {
            name:'Confirmation received',
            component:ConfirmationStep({info:JSON.stringify(state.transactionData)})
        }
    ];
    
    const backClicked = ()=>{
        if(state.activeIndex>0){
            setState({
                ...state,
                activeIndex:--state.activeIndex
            });
        }
    }

    const nextClicked = ()=>{
        if(state.activeIndex<(steps.length-1)){
            setState({
                ...state,
                activeIndex:++state.activeIndex
            });
        }
    }
    
    const renderActiveStep = ()=>{
        return steps[state.activeIndex].component;
    }

    if(state.openSettings){
        //SettingsDrawer
        return (
            <Box sx={{ width:'100%' }}>
                <SettingsDrawer isOpened allowClose={false} onClose={()=>{setState({...state, openSettings:!AppSettings.RudimentarySettingsProvided})}}/>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={state.activeIndex}>
                {steps.map((step, index) => {
                    return (
                        <Step key={step.name}>
                            <StepLabel>{step.name}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {renderActiveStep()}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={state.activeIndex === 0}
                    onClick={backClicked}
                    sx={{ mr: 1 }}
                >
                {state.activeIndex < steps.length-1 ? <ArrowBackIosIcon/> : null}    
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {state.activeIndex <= 1 ? <Button disabled={!(state.activeIndex<steps.length-2)} onClick={nextClicked} sx={{ mr: 1 }}>
                    <ArrowForwardIosIcon/>
                </Button> : null}
            </Box>
            {state.activeIndex === 0 ? <Button onClick={()=>{setState({...state, openSettings:true})}} variant="outlined">Settings</Button> : null}
        </Box>
       
    );
}