import algosdk, { Algodv2, Transaction } from "algosdk";
import { AsaBase } from "./Asa";

export class AlgoSDKHelper{

    private _algoSdkClient!:Algodv2;
    private _algodServer?:string;
    private _indexerServer?:string;

    constructor(private _testnet:boolean = false){
        this._algodServer =  _testnet ? 'https://node.testnet.algoexplorerapi.io' : 'https://node.algoexplorerapi.io';
        this._indexerServer = _testnet ? 'https://algoindexer.testnet.algoexplorerapi.io' : 'https://algoindexer.algoexplorerapi.io/'
        this._algoSdkClient = new algosdk.Algodv2('', this._algodServer, 443);
    }

    public async sendRawTransaction(signed:Uint8Array):Promise<void>{
        await this._algoSdkClient.sendRawTransaction(signed).do();
    }

    public async waitForConfirmation (txId:string) {
        let invokactionsCount = 0;
        while (true && ++invokactionsCount<10) {
            const url = `${this._indexerServer}/v2/transactions/${txId}`;
            try{
                let response = await fetch(url);
                if (response.status !== 200) {
                    await this.wait(2000);
                } else {
                    const data = await response.json() as any;
                    if(Number.parseInt(data['current-round']) >= data['transaction']['confirmed-round']){
                        return data['transaction'];
                    }
                }
            }
            catch(e){
                debugger;
            }
        }
    };

    public async createTransaction(from:string, to:string, amount:number, note:string, asaIndex:number=0):Promise<Transaction>{
        let params = await this._algoSdkClient.getTransactionParams().do();
        if(asaIndex!==0){
            return algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: from, 
                to: to, 
                amount: amount, 
                note: this.encodeMemo(note),
                suggestedParams: params,
                assetIndex:asaIndex
            });
        }
        else{
            return algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                from: from, 
                to: to, 
                amount: amount, 
                note: this.encodeMemo(note),
                suggestedParams: params
            });
        }
    }

    public async transactionWithMemoConfirmed(targetWallet:string, memo:string, cancellation?:Promise<void>):Promise<boolean>{
        let invoke = true;
        cancellation?.then(()=>{invoke = false;});
        while (invoke) {
            const url = `${this._indexerServer}/v2/accounts/${targetWallet}/transactions?note-prefix=${btoa(memo)}&tx-type=pay`;
            try{
                let response = await fetch(url);
                if (response.status !== 200) {
                    await this.wait(2000);
                } else {
                    
                    const data = await response.json() as any;
                    if(data['transactions'].length>0){
                        return data['transactions'];
                    }
                    await this.wait(2000);
                }
            }
            catch(e){
                console.error(e);
            }
        }
        return false;
    }

    public async getAsaById(id:number):Promise<AsaBase|null>{
        const url = `${this._indexerServer}/v2/assets?asset-id=${id}`;
        let response = await fetch(url);
        const data = await response.json();
        if(!data.assets || data.assets.length!==1){
            return null;
        }
        const el = data.assets[0];
        return new AsaBase(id, el.params.decimals, el.params['unit-name']);
    }

    public encodeUnsignedTransaction(txn: Transaction):Uint8Array{
        return algosdk.encodeUnsignedTransaction(txn);
    }

    public encodeMemo(val:string):Uint8Array{
        const enc = new TextEncoder();
        return enc.encode(val);
    }

    public decodeMemo(array:Uint8Array):string{
        return new TextDecoder().decode(array);
    }

    private wait(milliseconds:number):Promise<void>{
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
}