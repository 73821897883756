import {Search} from "@mui/icons-material";
import { Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { AlgoSDKHelper } from "../Algo/AlgoSDKHelper";
import { AsaBase } from "../Algo/Asa";
import { AppSettings } from "../Common/AppSettings";

export interface AsaSelectorProps{
    currentAsaId?:number;
    currentAsa:AsaBase;
    onAsaSelectionChanged?:(asa:AsaBase)=>void;
}

export const AsaSelector = (props:AsaSelectorProps)=>{
    
    const invokeAsaSearchAndSetState = async(id:string|number):Promise<void>=>{
        setIsSearching(true);
        let parsed = typeof(id) === 'string' ? Number.parseInt(id) : id;
        if(parsed>0){
            const helper = new AlgoSDKHelper(AppSettings.IsTestnet);
            let asa = await helper.getAsaById(parsed);
            if(asa!==null){
                setCurrentAsa(asa);
                props.onAsaSelectionChanged && props.onAsaSelectionChanged(asa);
            }
        }
        else if(id===''){
            let asa = AsaBase.AsAlgo();
            setCurrentAsa(asa);
            props.onAsaSelectionChanged && props.onAsaSelectionChanged(asa);
        }
        setIsSearching(false);
    }

    const [searchTerm, setSearchTerm] = useState(props.currentAsa?.id?.toString() || '');
    const [currentAsa, setCurrentAsa] = useState<AsaBase>(props.currentAsa);
    const [isSearching, setIsSearching] = useState(false);

    const inputChanged = (ev:any)=>{
       setSearchTerm(ev.currentTarget.value);
    }

    return (
        <React.Fragment>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Selected ASA: {currentAsa?.name}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    value={searchTerm}
                    onChange={inputChanged}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{invokeAsaSearchAndSetState(searchTerm)}}
                        onMouseDown={()=>{invokeAsaSearchAndSetState(searchTerm)}}
                        edge="end"
                        >
                            <Search />
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            {isSearching ? <CircularProgress/> : null}
        </React.Fragment>
    )
}